<template>
<div class="upload-btn">
    <el-button type="primary" icon="el-icon-plus" @click="handleDialogShow('')">上传文件</el-button>

    <el-dialog :title="editForm.id ? '修改文件' : '新增文件'" :visible.sync="dialogVisible" width="400px" :closed="handleDialogClose">
        <div class="form">
            <div class="form-item">
                <div class="form-item-name">所属文件夹</div>
                <div class="form-item-content">
                    <el-cascader v-model="editForm.dirId" :options="dirTree"
                        clearable :props="dirProps"></el-cascader>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-name">选择文件</div>
                <div class="form-item-content">
                    <el-upload class="el-upload" :action="uploadUrl" :data="{ type: uploadSuffix.OTHER }"
                        :show-file-list="false" :file-list="fileList" :on-success="handleAddFile"
                        :before-upload="loadingStart" accept=".pdf">
                        <el-button type="primary" icon="el-icon-upload">上传
                        </el-button>
                    </el-upload>
                </div>
            </div>
            <div class="form-item" v-if="editForm.file">
                <div class="form-item-name">文件名</div>
                <div class="form-item-content">
                    <el-input class="" v-model="editForm.name" placeholder="请输入文件名"></el-input>
                </div>
            </div>
            <div class="form-item">
                <div class="form-item-name">权重</div>
                <div class="form-item-content">
                    <el-input class="" v-model.number="editForm.sort" placeholder="请输入权重"></el-input>
                </div>
            </div>
        </div>
        <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
            <el-button type="primary" @click="handleSave">保存</el-button>
            <el-button @click="handleDialogClose">取消</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import Core from 'core';

export default {
    data() {
        return {
            uploadUrl: Core.Const.NET.FILE_UPLOAD_END_POINT,
            uploadSuffix: Core.Const.UPLOAD_FILE_SUFFIX,
            downloadUrl: Core.Const.NET.FILE_URL_PREFIX,

            fileList: [],
            loading: false,
            dialogVisible: false,
            dirTree: [],
            editForm: {
                id: '',
                name: '',
                file: '',
                dirId: '',
                sort: '',
            },
            dirProps: {
                emitPath: false,
                label: 'name',
                value: 'id',
                checkStrictly: true
            }
        };
    },
    watch: {},
    computed: {},
    created() {
        this.initDirTree();
    },
    mounted() {},
    methods: {
        initDirTree() {
            Core.Api.ResourceDir.tree().then((res) => {
                this.dirTree = res.list;
            });
        },
        handleDialogShow(item = '') {
            console.log(item)
            if (item) {
                this.editForm.id = item.id;
                this.editForm.name = item.name;
                this.editForm.file = item.files;
                this.editForm.dirId = item.dir_id;
                this.editForm.sort = item.sort;
            }
            this.dialogVisible = true;
        },
        handleDialogClose() {
            this.dialogVisible = false;
            this.editForm = {
                id: '',
                name: '',
                file: '',
                dirId: '',
                sort: '',
            };
        },
        handleSave() {
            if (
                !this.editForm.dirId ||
                !this.editForm.name ||
                !this.editForm.file
            ) {
                this.$message.warning('请将信息填写完整');
                return;
            }
            Core.Api.Resource.saveFile(
                this.editForm.id,
                this.editForm.dirId,
                this.editForm.name,
                this.editForm.file,
                this.editForm.sort,
            ).then((res) => {
                this.handleDialogClose();
                this.$emit('handleUpdate')
                console.log(res);
            });
        },
        handleAddFile(res, file, fileList) {
            console.log(file);
            this.editForm.file = file.response.data.name;
            this.editForm.name = file.name;
            this.loadingStop();
        },
        loadingStart() {
            this.loading = true;
        },
        loadingStop() {
            this.loading = false;
        }
    }
};
</script>
<style lang="scss" scoped>
.upload-btn {
    .form {
        width: 100%;
        .form-item {
            margin-bottom: 20px;
            @include flex(row, flex-start, center);
            &:last-child {
                margin-bottom: 0px;
            }
            .form-item-name {
                font-weight: 500;
                width: 30%;
            }
            .form-item-content {
                width: 70%;
            }
        }
    }
}
</style>